import {on} from 'delegated-events'

function handleChange(event: Event) {
  const checkbox = event.target as HTMLInputElement

  const submitButton = document.querySelector('.js-scim-configuration-submit')
  if (!submitButton) return

  submitButton.removeAttribute('disabled')

  if (!checkbox.checked) {
    const dialogId = 'disable-scim-dialog'
    submitButton.setAttribute('id', `dialog-show-${dialogId}`)
    submitButton.setAttribute('data-show-dialog-id', dialogId)
  } else {
    submitButton.removeAttribute('id')
    submitButton.removeAttribute('data-show-dialog-id')
  }
}

on('change', '#scim_configuration', handleChange)
