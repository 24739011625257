import {onInput} from '@github-ui/onfocus'
import {on} from 'delegated-events'

// Show "Specify your industry" custom input on selection of "Other" industry
on('change', '.js-enterprise-trial-industry', function (event) {
  const target = event.target as HTMLSelectElement

  const selectedIndustry = target.value

  const otherIndustryField = document.querySelector<HTMLElement>('.js-enterprise-trial-other-industry-field')
  const nextFormField = otherIndustryField?.nextElementSibling
  if (otherIndustryField) {
    if (selectedIndustry === otherIndustryField.getAttribute('data-trigger-option-value')!) {
      otherIndustryField.hidden = false
      if (nextFormField) {
        nextFormField.classList.remove('pl-2')
      }
    } else {
      otherIndustryField.hidden = true
      nextFormField?.classList.add('pl-2')
    }
  }
})

// Hide org invite warning if user selects an org from the dropdown menu
on('change', '.js-enterprise-trial-org-selection', function () {
  const orgInviteWarningWrapper = document.getElementsByClassName(
    'js-enterprise-trial-org-invite-warning',
  )[0] as HTMLDivElement
  if (orgInviteWarningWrapper) {
    orgInviteWarningWrapper.hidden = true
  }
})

onInput('.js-business-shortcode', function (event) {
  const shortcodeInput = event.target as HTMLInputElement

  const adminNameSpan = document.querySelector('.js-business-first-admin-name')
  if (!(adminNameSpan instanceof HTMLElement)) return

  adminNameSpan.textContent = `${shortcodeInput.value}_admin`
})
